import React, { useEffect, useState } from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import { navigate } from "@reach/router";
import queryString from "query-string";

import { languageCodeMappings } from "../data/languages";
import Icon from "../assets/logo.svg";
import "./index.css";

const WEWORK_HP_ROAM_URL = "wework://com.hp.roam";
const PRINT_HUB_URL = "https://members.wework.com/print";

/**
 * Detect browser language using standard Web API. Cross reference detected language to our supported language codes
 * If langauge cannot be determined, returns English language code
 * @see https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/language
 * @returns {string} Language code e.g "nl-NL"
 */
const getBrowserLanguage = () => {
  if (window.navigator && window.navigator.language) {
    // Map browser language code to our supported lang codes
    const lang = languageCodeMappings[window.navigator.language];
    return lang ? lang : "en-US";
  }
  return "en-US";
};

const parseJwt = token => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(c => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

const isWindows = () => window.navigator.platform.includes('Win');

// build a wework:// url from the query and hash params
//  for example (Auth0 version), convert from: https://print-login-success.wework.com/en-US?id_token=&nonce=&state=#id_token=the-token&state=ce59e564-26a0-40a5-85fa-0658c8c2a65e
//  for example (we.id version), convert from: https://print-login-success.wework.com/en-US?id_token=the-token&nonce=2bc79c30-49b5-4531-ab43-db4e9b8c78e6&state=230f24a3-3cbb-4178-8788-f1d8276c459a
//  to: wework://com.hp.roam?id_token=the-token&state=cbc7241d-4e40-4f57-86b2-bda32f45e045&nonce=9ca4036a-1cb6-434f-8897-25e580536a47#id_token=the-token&state=cbc7241d-4e40-4f57-86b2-bda32f45e045&nonce=9ca4036a-1cb6-434f-8897-25e580536a47
const buildWeWorkUrl = () => {
  const queryParams = queryString.parse(window.location.search);
  const hashParams = queryString.parse(window.location.hash);
  const mergedParams = {};

  Object.keys(queryParams).forEach(key => {
    // omit null/blank values
    if (queryParams[key]) {
      mergedParams[key] = queryParams[key];
    }
  });

  Object.keys(hashParams).forEach(key => {
    // omit null/blank values
    if (hashParams[key]) {
      mergedParams[key] = hashParams[key];
    }
  });

  if (!mergedParams["nonce"] && mergedParams["id_token"]) {
    const tokenClaims = parseJwt(mergedParams["id_token"]);
    mergedParams["nonce"] = tokenClaims["nonce"];
  }

  // this is a hack to support the windows driver auth flow which cant use id tokens because of HP library bugs.
  // the windows driver will request an access_token instead of an id_token since the access_token is smaller and
  // not broken by bugs in the hp protocol handler which truncates the redirect url at 1024 characters.
  // additionally, since we do not have an id_token, we must use the state param as the value of the nonce as well
  // since the state param is echoed back in the response from the idp but the nonce param is not.
  const idToken = mergedParams["id_token"] || mergedParams['access_token'];
  const stateParam = mergedParams["state"];
  const nonceParam = mergedParams["nonce"] || stateParam;

  const params = queryString.stringify({ id_token: idToken, state: stateParam, nonce: nonceParam });

  // omit the hash params in windows, because HP's protocol handler doesnt handle hash params correctly
  return isWindows() ? `${WEWORK_HP_ROAM_URL}?${params}` : `${WEWORK_HP_ROAM_URL}?${params}#${params}`;
};

const IndexPage = ({ location }) => {
  const [redirectUri, setRedirectUri] = useState();

  // Landing on the root route determines lang and navigates route to load the correct lang page
  useEffect(() => {
    if (location && location.pathname === "/") {
      let url = "/";
      // Detect browser language
      const lang = getBrowserLanguage();
      if (lang) {
        url += lang;
      }
      if (typeof window !== "undefined") {
        url += window.location.search ? window.location.search : "";
        url += window.location.hash ? window.location.hash : "";
      }

      navigate(url, {
        state: {
          detectedLanguage: lang
        }
      });
    }
    return;
  });

  useEffect(() => {
    // Successful authentication (via WeAuth) will provide query/hash params (auth token, nonce, state) to our site,
    // which we grab and build into full URI to redirect to driver
    const redirectUrl = buildWeWorkUrl();

    setRedirectUri(redirectUrl);
    // Prompt browser to open driver
    if (location && location.pathname !== "/") {
      window.location.href = redirectUrl;
    }
  }, [location, setRedirectUri]);

  // No DOM rendered until language detected
  if (location && location.pathname === "/") {
    return null;
  }

  return (
    <div className="container">
      <Icon className="logo" />
      <div className="hed" dir="auto">
        <FormattedMessage id="redirecting" defaultMessage="Redirecting..." />
      </div>
      <div className="dek" dir="auto">
        <FormattedMessage
          id="please_click_open_print"
          defaultMessage={
            'Please click "Open Print" when you see the system dialog.'
          }
        />
      </div>
      <div className="lede" dir="auto">
        <FormattedMessage
          id="if_nothing_prompts_from_the_browser"
          defaultMessage="If nothing prompts from the browser, <a>click here</a>."
          values={{
            a: msg => (
              <a className="link" href={redirectUri}>
                {msg}
              </a>
            )
          }}
        />
      </div>
      <div className="nugraf" dir="auto">
        <FormattedMessage
          id="if_you_cannot_install_the_application"
          defaultMessage="If you cannot install the application, print using <a>our drag and drop tool</a>."
          values={{
            a: msg => (
              <a className="link" href={PRINT_HUB_URL}>
                {msg}
              </a>
            )
          }}
        />
      </div>
    </div>
  );
};

export default IndexPage;
