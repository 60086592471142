module.exports = {
  // Map browser language to our language codes, because they may slightly differ
  languageCodeMappings: {
    "en-US": "en-US",
    "ar": "ar", 
    "ca": "ca",
    "zh": "zh-CN",
    "zh-CN": "zh-CN",
    "zh-TW": "zh-TW",
    "cs-CZ": "cs-CZ",
    "nl": "nl-NL",
    "nl-NL": "nl-NL",
    "fr": "fr-FR",
    "fr-FR": "fr-FR",
    "fr-CA": "fr-CA",
    "de": "de-DE",
    "de-DE": "de-DE",
    "id": "id-ID",
    "id-ID": "id-ID",
    "it": "it-IT",
    "ja": "ja-JP",
    "ja-JP": "ja-JP",
    "ko": "ko-KR",
    "ko-KR": "ko-KR",
    "ms": "ms-MY",
    "ms-MY": "ms-MY",
    "pl": "pl-PL",
    "pl-PL": "pl-PL",
    "pt": "pt-BR",
    "pt-BR": "pt-BR",
    "ru": "ru-RU",
    "ru-RU": "ru-RU",
    "es": "es-ES",
    "es-ES": "es-ES",
    "es-LA": "es-LA",
    "sv": "sv-SE",
    "sv-SE": "sv-SE",
    "th": "th-TH",
    "th-TH": "th-TH",
    "vi": "vi-VN",
    "vi-VN": "vi-VN",
  }, 
  // See gatsby-plugin-intl options
  // https://github.com/wiziple/gatsby-plugin-intl
  langs: [
    "en-US",
    "ar",
    "ca",
    "zh-CN",
    "zh-TW",
    "cs-CZ",
    "nl-NL",
    "fr-FR",
    "fr-CA",
    "de-DE",
    "id-ID",
    "it-IT",
    "ja-JP",
    "ko-KR",
    "ms-MY",
    "pl-PL",
    "pt-BR",
    "ru-RU",
    "es-ES",
    "es-LA",
    "sv-SE",
    "th-TH",
    "vi-VN"
  ],
  defaultLang: "en-US"
};
